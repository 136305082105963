import React from "react"
import {Box, Grid, Typography} from "@material-ui/core";
import {SinglePage} from "../components/scaffold";
import {Link} from "gatsby-theme-material-ui";

export default function SiteNotice() {
  return (
    <SinglePage title="Impressum" showPollButton>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Impressum
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Im Auftrag der
              {' '}<Link color="inherit" href="https://www.privatfunk.de/" target="_blank">
              APR
            </Link>{' '}
              und
              {' '}<Link color="inherit" href="https://www.vau.net/" target="_blank">
              VAUNET
            </Link>{' '}
              wird diese Seite betrieben von
            </Typography>
          </Grid>


          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Anschrift
            </Typography>
            <Typography variant="body1" gutterBottom>
              SOLE Software GmbH<br/>
              Sommerbergstraße 97<br/>
              66346 Püttlingen<br/>
              Deutschland
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Kontakt
            </Typography>
            <Typography variant="body1" gutterBottom>
              Telefon: +49 6806 4999535-0<br/>
              E-Mail: <Link to="mailto:kontakt@solesoftware.de" color="secondary">
              kontakt@solesoftware.de
            </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Vertreten durch
            </Typography>
            <Typography variant="body1" gutterBottom>
              Dominik Leibenger
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Umsatzsteuer-ID
            </Typography>
            <Typography variant="body1" gutterBottom>
              DE 306 343 092
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Registereintrag
            </Typography>
            <Typography variant="body1" gutterBottom>
              Handelsregistereintrag, Amtsgericht Saarbrücken, HRB 103260
            </Typography>
          </Grid>

        </Grid>
      </Box>
    </SinglePage>
  );
}
